<template>
  <v-container id="supplier-assignment" fluid tag="section">
    <div v-if="saving" class="spinner-box">
      <md-progress-spinner
        class="color-stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-row justify="center">
      <v-col cols="12" class="mt-1">
        <!-- <div class="subtitle-4 font-weight-bold mb-5">
          Assign / Unassign suppliers
        </div> -->
        <v-container class="py-0" v-if="!loading">
          <v-row>
            <v-col cols="12" lg="6">
              <div
                class="verification-alert-container"
                v-if="!isAccountVerified"
              >
                <div>
                  <div>
                    Your company profile needs to be verified to assign and
                    unassign suppliers.
                  </div>
                  <ui-button
                    class="primary long"
                    :disabled="$store.state.accountVerificationRequested"
                    :title="'Request Verification'"
                    @click="requestVerificationForAccount"
                  />
                </div>
                <md-divider class="mb-5" />
              </div>

              <div class="md-subheading assigned_suppliers_subheading_text">
                Assigned Suppliers
              </div>
              <div v-if="assignedSuppliers.length > 0">
                <v-row
                  v-for="supplier in assignedSuppliers"
                  :key="supplier.supplier_id"
                >
                  <v-col cols="6" class="form-label">
                    <div>
                      <span class="supplier-name">{{
                        supplier.supplier.name
                      }}</span>
                      <!-- <span class="md-subhead">{{ supplier.brand_name }}</span> -->
                    </div>
                  </v-col>
                  <v-col cols="6" class="form-value">
                    <ui-button
                      small
                      className="danger"
                      title="Unassign"
                      class="unassign_btn_class"
                      data-cy="supplier_assignment__btn-unassign"
                      @click="unassign(supplier.supplier)"
                      :disabled="saving || !isAccountVerified"
                    >
                      <v-icon dark class="mr-1">mdi-account-cancel</v-icon>
                    </ui-button>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col
                    cols="12"
                    class="form-value"
                    style="text-align: center"
                  >
                    <label>No suppliers assigned yet.</label>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <form novalidate class="supplier-form">
                <div class="supplier-search-bar">
                  <md-field class="field_size">
                    <label class="color_class" for="search-query">Search</label>
                    <md-input
                      name="search"
                      id="search"
                      v-model="query"
                      @input="debounceSearch"
                      @keydown.enter.prevent
                      :disabled="loading"
                    />
                  </md-field>
                </div>
              </form>
              <v-row class="request-field-size">
                <span class="span-label">
                  <a
                    href="#"
                    @click.prevent="handleClick"
                    class="clickable-link"
                  >
                    I'm unable to find the business
                  </a>
                </span>
                <organization-request
                  :active="organizationRequesting"
                  @close="organizationRequesting = false"
                />
              </v-row>
              <v-row
                class="supplier-list"
                v-if="suppliers.length > 0 && !loadingSuppliers"
              >
                <v-col
                  cols="12"
                  v-for="supplier in suppliers"
                  :key="supplier.id"
                >
                  <div class="md-layout-item supplier-list__item">
                    <md-card class="card-style">
                      <div class="ml-3">
                        <span class="supplier-name">
                          <v-tooltip color="#6A7895" bottom>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="verify-icon-container">
                                <v-icon
                                  @mouseenter="hoveredStar = supplier.id"
                                  @mouseleave="hoveredStar = null"
                                  @click="toggleFavorite(supplier)"
                                  :class="{
                                    'star-selected': supplier.favorite,
                                    'enlarge-star': hoveredStar === supplier.id,
                                  }"
                                  style="margin-right: 10px"
                                >
                                  {{
                                    supplier.favorite
                                      ? "mdi-star"
                                      : "mdi-star-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>
                            <div class="hover-container">
                              <span>{{
                                supplier.favorite
                                  ? "Remove from favourites"
                                  : "Add to favourites"
                              }}</span>
                            </div>
                          </v-tooltip>
                          {{ supplier.name }}
                          <v-tooltip
                            v-if="supplier.account && supplier.account.verified"
                            color="#6A7895"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="verify-icon-container">
                                <verify-icon />
                              </div>
                            </template>
                            <div class="hover-container">
                              <span>Verified</span>
                            </div>
                          </v-tooltip>
                        </span>
                      </div>
                      <div>
                        <ui-button
                          small
                          className="success-full"
                          class="btn_class"
                          title="Assign"
                          v-if="!isAssignedSupplier(supplier.id)"
                          @click="assign(supplier)"
                          :disabled="saving || !isAccountVerified"
                        >
                          <v-icon dark class="mr-1">how_to_reg</v-icon>
                        </ui-button>
                        <ui-button
                          small
                          className="danger"
                          class="btn_class"
                          title="Unassign"
                          data-cy="supplier_assignment__btn-unassign"
                          v-else
                          @click="unassign(supplier)"
                          :disabled="saving || !isAccountVerified"
                        >
                          <v-icon dark class="mr-1">mdi-account-cancel</v-icon>
                        </ui-button>
                      </div>
                    </md-card>
                  </div>
                </v-col>
                <v-row class="ml-2">
                  <v-col cols="12">
                    <div class="pagination-style">
                      <Pagination
                        :current-page="currentPage"
                        :total-count="totalCount"
                        @load-page="loadPage"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <v-row v-else-if="loadingSuppliers">
                <v-col cols="12" class="text-center">
                  <md-progress-spinner
                    class="color_stroke"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">No suppliers found.</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import debounce from "lodash/debounce";
import Pagination from "../../components/Pagination";
import store from "@/store";
import auth from "@/auth";
import VerifyIcon from "@/assets/icons/VerifyIcon.vue";

export default {
  name: "supplierAssignment",
  components: {
    Pagination,
  },
  data: () => ({
    model: {
      id: null,
      styleNumber: null,
      productType: null,
      category: [],
      quantity: null,
      noOfColors: null,
      material: null,
      brand_id: null,
      created_by_organization: null,
      selectedServices: [],
      serviceDeadlines: {},
      currency: null,
      additionalInformation: null,
      files: [],
    },
    organizationRequesting: false,
    productTypes: [],
    materials: [],
    currencies: [],
    services: [],
    categories: [],
    hoveredStar: null,
    saving: false,
    loading: false,
    loadingSuppliers: false,
    suppliers: [],
    assignedSuppliers: [],
    query: "",
    currentPage: 1,
    totalCount: 0,
  }),
  mounted() {
    const inquiryId = this.$route.params.id;

    this.getInquiry(inquiryId).then((response) => {
      const inquiry = response.data.inquiry;

      this.model.id = inquiry.id;
      this.model.styleNumber = inquiry.style_number;
      this.model.productType = inquiry.product_type;
      this.model.category = inquiry.categories;
      this.model.quantity = inquiry.bulk_order_quantity;
      this.model.noOfColors = inquiry.colour_number;
      this.model.material = inquiry.materials;
      this.model.currency = inquiry.currency;
      this.model.additionalInformation = inquiry.additional_information;
      this.model.createdBy = inquiry.created_by;
      this.model.accountManagerId = inquiry.account_manager_id;
      this.model.created_by_organization =
        inquiry.collection.created_by_organization_id;
      this.model.brand_id = inquiry.collection.brand_id;

      // TODO: service deadlines are not retreive correclty

      this.assignedSuppliers = [];
      response.data.suppliers.forEach((supplier) => {
        supplier.id = supplier.supplier_id;
        this.assignedSuppliers.push(supplier);
      });
    });

    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
    this.getCategories().then((response) => {
      this.categories = response.data.categories;
    });

    this.getSuppliers(auth.user.account.organization_registry_id);
  },
  computed: {
    isAccountVerified() {
      return auth.user.account?.verified;
    },
  },
  methods: {
    debounceSearch: debounce(function () {
      this.currentPage = 1;
      this.loadingSuppliers = true;
      this.getSuppliers(auth.user.account.organization_registry_id);
    }, 500),
    back_to_inquiry() {
      this.$emit("show-options", 1);
    },
    getSuppliers(organizationId) {
      this.loadingSuppliers = true;
      let url =
        "/api/organization_registry?page=" +
        this.currentPage +
        "&type=supplier";
      if (this.query) {
        url += "&name=" + this.query;
      }
      url += "&organizationId=" + organizationId;

      restAdapter.get(url).then((response) => {
        this.suppliers = response.data.data;
        this.totalCount = response.data.total;
        this.loadingSuppliers = false;
      });
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getProductTypeName(id) {
      let name = "";
      this.productTypes.forEach((productType) => {
        if (productType.id == id) {
          name = productType.name;
        }
      });

      return name;
    },
    getCategoryName(id) {
      let name = "";
      this.categories.forEach((category) => {
        if (category.id == id) {
          name = category.name;
        }
      });

      return name;
    },
    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      this.openOrganizationRequest();
    },
    getMaterialName(id) {
      let name = "";
      this.materials.forEach((material) => {
        if (material.id == id) {
          name = material.name;
        }
      });

      return name;
    },
    getCurrencyName(code) {
      let name = "";
      this.currencies.forEach((currency) => {
        if (currency.currency_code == code) {
          name = currency.display_name;
        }
      });

      return name;
    },
    isAssignedSupplier(supplierId) {
      const supplier = this.assignedSuppliers.find(
        (supplier) => supplier.supplier_id === supplierId
      );
      return supplier !== undefined;
    },
    assign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      restAdapter
        .post(`/api/inquiries/${this.model.id}/assign_supplier`, data)
        .then((response) => {
          notification.success(supplier.name + " assigned successfully");
          this.$emit("supplier-assigned", 1);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
            this.saving = false;
          });
        });
    },
    toggleFavorite(supplier) {
      const data = {
        supplierId: supplier.id,
      };

      restAdapter
        .post("/api/favourite_suppliers", data)
        .then((response) => {
          const favorited = response.data.favorited;

          if (favorited) {
            supplier.favorite = true;
            notification.success(`Added ${supplier.name} to favorites`);
          } else {
            supplier.favorite = false;
            notification.success(`Removed ${supplier.name} from favorites`);
          }
        })
        .catch((error) => {
          notification.error("Error while toggling favorite:", error);
        });
    },
    unassign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      const confirmed = confirm(
        "The supplier will also be removed from BOM, Digital Product Passport and Timeline. Are you sure you want to unassign '" +
          supplier.name +
          "'?"
      );

      if (!confirmed) {
        this.saving = false;
        return;
      }

      restAdapter
        .delete(`/api/inquiries/${this.model.id}/remove_supplier`, data)
        .then((response) => {
          notification.success(supplier.name + " unassigned successfully");
          this.$emit("supplier-assigned", 1);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });

            this.saving = false;
          });
          if (
            auth.user.account.organization_registry_id === supplier.id &&
            supplier.id !== this.model.created_by_organization &&
            supplier.id !== this.model.brand_id
          ) {
            this.$router.push("Collections");
            return;
          }
        });
    },
    requestVerificationForAccount() {
      const id = auth.user.account?.organization_registry_id;
      if (!id) {
        return;
      }
      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          store.commit("SET_ACCOUNT_VERIFICATION_REQUESTED", true);
          notification.success(
            "Profile Verification request sent to Seamless Source"
          );
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },
    loadPage(page) {
      this.currentPage = page;
      this.getSuppliers(auth.user.account.organization_registry_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-form {
  margin-top: -30px;
  justify-content: center;
}

.empty-layout-item {
  margin-top: -48px;
}

.form-label {
  color: $primaryDark !important;
  font-weight: 400;
  text-align: right;
}

.enlarge-star {
  transform: scale(1.6);
  transition: transform 0.2s ease;
}

.form-value {
  color: $primaryDark;
  font-weight: 400;
  text-align: left;
}

.supplier-list {
  .md-title,
  .md-subhead {
    color: $primaryDark;
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: $cardBackground;
  }
}
.verify-icon-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
}
.supplier-search-bar {
  text-align: right;
  display: flex;
}
.verify-icon-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
}
.search_class {
  width: 140px;
  height: 36px;
  border-radius: 3px;
}
.spinner-box {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 999;
}

.supplier-list-layout {
  padding: 10px 0 10px 15px;
}

a {
  color: #442d65 !important;
}
.star-selected {
  color: gold;
}
.supplier-name {
  align-content: center !important;
  color: $primaryDark;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.card-style {
  margin-right: 10px;
  width: 513px;
  min-height: 55px;
  border-radius: 3px;
  border: 0.5px solid $primaryDarkest;
  background: $paginateBackground;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}

.supplier-brand {
  color: $primaryDark;
  text-align: left;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: $spinnerStroke !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: $primaryDarkest !important;
}

.list_color.md-theme-default::v-deep .md-list-item-text {
  color: $primaryDarkest !important;
}

.list_color.md-selected::v-deep .md-checked.md-primary .md-checkbox-container {
  background-color: transparent !important;
  border-color: $primaryDarkest !important;
  border-radius: 4px !important;
}

.list_color::v-deep .md-checkbox.md-theme-default .md-checkbox-container {
  background-color: transparent !important;
  border-color: $primaryDarkest !important;
  border-radius: 4px !important;
}

.list_color.md-selected::v-deep
  .md-checkbox.md-theme-default.md-checked
  .md-checkbox-container:after {
  border-color: $primaryDarkest !important;
}

.supplier_roles_container {
  padding-top: 10px;
  margin-top: -10px !important;
  background-color: $popupWindow;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1);
  border-radius: 4.25385px;
}

.color_class {
  border: none;
  color: $primaryDarkest !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.md-field.md-theme-default:before {
  background-color: $primaryDarkest !important;
}

.text_color {
  justify-content: center !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 13.7378px;
  line-height: 116.1%;
  display: flex;
  align-items: center;
  color: $primaryDark !important;
}

.subheading_text {
  color: $primaryDark;
  font-weight: 600;
  margin-bottom: 30px;
  margin-right: 180px;
  text-decoration-line: underline;
}
.assigned_suppliers_subheading_text {
  color: $assignButton;
  font-weight: 600;
  margin-bottom: 30px;
  text-decoration-line: underline;
}

.arrow_icon {
  font-size: 25px;
  color: $primaryDarkest;
}

.back_btn_class {
  width: 149px;
  height: 36px;
  margin-top: -5px;
  margin-left: 17px;
  background-color: rgba(71, 48, 104, 0.1) !important;
  border-radius: 81.2308px;
}

.back_btn_class:hover {
  cursor: pointer;
}

.md-card {
  box-shadow: none !important;
}
.unassign_btn_class {
  margin-top: -2px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}
.btn_class {
  margin-left: 35px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}

.field_size {
  width: 100% !important;
  margin-right: 40px;
  margin-bottom: 20px;
}

.pagination-style {
  margin-top: 5px;
  margin-left: 0px;
  width: 513px;
}

.supplier-brand-name {
  color: $primaryDark;
}

.request-field-size {
  margin: -5px 40px 10px 0px;
  justify-content: right;
}

.verification-alert-container > div {
  padding: 0px 0px 15px 0px;
}
.verification-alert-container div {
  color: $dangerBackground;
}
</style>
